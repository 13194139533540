import React from 'react'

const four04 = () => {
    return (
        <div>
            <h1>404</h1>
            <p>Nothing to see here.</p>
        </div>
    )
}

export default four04
